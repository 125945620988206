// @tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --ant-red-5: #ff4d4f;
  --ant-green-5: #73d13d;
  --ant-green-5-opacity-50: rgba(115, 209, 61, 0.5);
  --ant-blue-5: #40a9ff;
  --blue-900: #11122C;
}

a {
  color: var(--blue-400);
  text-decoration: none;
}

.editor-content a {
  color: #40a9ff !important;
  text-decoration: underline;
}

*,
::before,
::after {
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb;
}

svg {
  flex-shrink: 0;
}

html {
  font-size: 16px;
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  font-family: "Inter", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--blue-900);
  height: 100%;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.mantine-Table-root td,
.mantine-Table-root th {
  background-color: white;
}

.mantine-Table-tr {
  a {
    color: #1A2433 !important;
  }

  color: #1A2433 !important;
  border-color: #DEE2E6 !important;

  a:hover {
    color: #228BE6 !important;
  }
}

.mantine-Table-th {
  border-top: 1px solid #DEE2E6 !important;
}

.mantine-Table-td {
  padding: 16px 12px !important;
}

.mantine-Paper-root {
  border: none !important;
}

.mrt-show-hide-columns-menu {
  width: 600px !important;
  padding: 24px;
  display: grid;
  grid-template-columns: auto auto auto;
  padding: 10px;

  .mantine-Divider-root {
    display: none;
  }
}

.breadcrumbs {
  font-size: 14px;

  a:hover {
    color: #75ADE3;
    text-decoration: none;
  }

  a:last-child {
    pointer-events: none;
    cursor: default;
    text-decoration: none;
    color: #1A2433;
  }
}


button {
  font-family: var(--font-space) !important;
  font-weight: 700 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

a {
  text-decoration: none;
  color: #0b7b94;
  cursor: pointer;
}

a:hover {
  text-decoration: underline;
  transition: 0.2s ease-in-out;
}

.heading {
  font-size: 42px;
  line-height: 48px;
  font-family: var(--font-space);
  font-weight: 600;
}

h1 {
  font-size: 42px;
  line-height: 48px;
  font-family: var(--font-space);
  font-weight: 600;
}

h2 {
  font-size: 36px;
  line-height: 26px;
  font-family: var(--font-space);
  font-weight: 700;
}

h3 {
  font-size: 32px;
  line-height: 42px;
  font-family: var(--font-inter);
  font-weight: 600;
}

h4 {
  font-size: 28px;
  line-height: 36px;
  font-family: var(--font-inter);
  font-weight: 600;
}

h5 {
  font-size: 24px;
  line-height: 28px;
  font-family: var(--font-inter);
  font-weight: 600;
}

h6 {
  font-size: 20px;
  line-height: normal;
  font-family: var(--font-inter);
  font-weight: 600;
}

p {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
}

.application-width {
  max-width: 90%;
  width: 100%;
}

@media (max-width: 768px) {
  .heading {
    font-size: 24px;
    line-height: 28px;
    font-family: var(--font-space);
    font-weight: 600;
  }

  h1 {
    font-size: 24px;
    line-height: 28px;
    font-family: var(--font-space);
    font-weight: 600;
  }

  h5 {
    font-size: 18px;
    line-height: 28px;
    font-family: var(--font-inter);
    font-weight: 600;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
}

@media (min-width: 768px) {
  .application-width {
    width: 85% !important;
  }
}

@media (min-width: 1280px) {
  .application-width {
    max-width: 1200px;
  }
}


.faq-chevron[data-rotate] {
  transform: rotate(45deg);
}

.mantine-Carousel-controls {
  opacity: 0;
}

.mantine-Carousel-slide .slider-card {
  height: -webkit-fill-available;
}

.mantine-Button-root,
.mantine-Input-input {
  width: -webkit-fill-available;
}

.file-card:hover .icon-file,
.file-card .icon-download {
  display: none;
}

.file-card:hover .icon-download {
  display: inline;
}

.post-card p {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px !important;
  line-height: 1.25rem;
}

.mantine-AppShell-root {
  background: #F3F3F7
}

.mantine-Stepper-step[data-progress] {
  border: 1px solid #0B7B94;
}

.mantine-Stepper-step[data-completed] {
  justify-content: space-between !important;

  .mantine-Stepper-stepWrapper {
    display: block !important;
  }
}

// PIN_INPUT
.mantine-PinInput-pinInput {
  .mantine-Input-input {
    border-radius: 16px !important;
    border-color: transparent !important;
    background: #f2f4f7 !important;
    transition: 0.2s ease-in-out !important;
    height: inherit !important;
    font-size: 24px !important;
    font-weight: 500 !important;

    &:focus {
      border-radius: 16px !important;
      border-color: #0b7b94 !important;
      background: #fff !important;
    }
  }
}

// FLOATING_INDICATOR
.indicator {
  background: #F3F3F7;
  border-radius: 64px;
}

.root {
  color: #A2A4A6;
  position: relative;
  background-color: white;
  ;
  border-radius: 64px;
  padding: 5px;
  height: 62px;
  border: 1px solid light-dark(var(--mantine-color-gray-2), var(--mantine-color-dark-4));
}


.control {
  text-align: center !important;
  line-height: 1;
  color: #A2A4A6;
  border-radius: var(--mantine-radius-md);
  font-size: var(--mantine-font-size-sm);
  transition: color 100ms ease;
  font-weight: 500;
  padding: 12px 75px;
}

.controlLabel {
  position: relative;
  z-index: 1;
}

// NavLink
.mantine-NavLink-root[data-active] {
  border-bottom: 3px solid #0B7B94;
}

.file-card:hover {
  .file-download {
    display: block !important;
  }
}

.mantine-Stepper-step:first-of-type {
  margin-top: 0 !important;
}